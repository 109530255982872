import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../containers/NotFound";
import {
    WelcomeContainer,
    LoginContainer,
    SignUpContainer,
    WorkspaceContainer,
    CareHomesContainer,
    MembersContainer,
    PatientsContainer,
    InvitationsContainer,
    AcceptInvitationContainer,
    SettingsContainer,
    HomePageContainer,
    ContactPageContainer,
} from "../util/helpers/lazyLoader";

export const AuthenticatedRoutes = () => {
    return (
        <Switch>
            <Route path="/" exact component={AcceptInvitationContainer} />
            <Route path="/welcome" exact component={WelcomeContainer} />
            <Route path="/workspace" exact component={WorkspaceContainer} />
            <Route
                path="/care-home-workspaces"
                exact
                component={CareHomesContainer}
            />
            <Route path="/patients" exact component={PatientsContainer} />
            <Route path="/team_members" exact component={MembersContainer} />
            <Route path="/invitations" exact component={InvitationsContainer} />
            <Route path="/settings" exact component={SettingsContainer} />
            <Route component={() => <NotFound redirect_pathname="/" />} />
        </Switch>
    );
};

export const UnauthenticatedRoutes = ({ isLoggedIn }) => {
    return (
            <Switch>
            <Route path="/"exact component={() => {
                            window.location.href = "/landing/index.html";
                            return null;
                        }}
            />
            <Route path="/contact" exact component={() => {
                            window.location.href = "/landing/contact.html";
                            return null;
                        }} />
            <Route path="/login" exact component={LoginContainer} />
            <Route path="/sign-up" exact component={SignUpContainer} />
            <Route component={() => <NotFound redirect_pathname="/" />} />
        </Switch>
    );
};
